<template>
  <div class="field is-grouped is-vcentered">
    <span class="control">{{ label }}</span>
    <div class="field has-addons">
      <div class="control">
        <div class="filter-menu-dropdown">
          <span class="radio-tick">
            <checkbox @input="$emit('toggle', action)" v-model="action" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BatchOptionsActionToggle',
  data: () => ({ action: false }),
  props: {
    label: {
      type: String,
      required: true
    }
  }
}
</script>
